import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import styled from 'styled-components'
import {Helmet} from "react-helmet";

export const query = graphql`
    query IndexQuery {
        allPrismicHome {
            edges {
                node {
                    data {
                        title {
                            text
                        }
                        description {
                            text
                        }
                        hero_title {
                            text
                        }
                        hero_text {
                            text
                        }
                        hero_image {
                            url
                            alt
                        }
                        cta1_image {
                            url
                            alt
                        }
                        cta1_header {
                            text
                        }
                        cta1_text {
                            text
                        }
                        cta1_link_text {
                            text
                        }
                        cta1_link {
                            url
                        }
                        cta2_image {
                            url
                            alt
                        }
                        cta2_header {
                            text
                        }
                        cta2_text {
                            text
                        }
                        cta2_link_text {
                            text
                        }
                        cta2_link {
                            url
                        }
                        cta3_image {
                            url
                            alt
                        }
                        cta3_header {
                            text
                        }
                        cta3_text {
                            text
                        }
                        cta3_link_text {
                            text
                        }
                        cta3_link {
                            url
                        }
                        about_image {
                          url
                          alt
                        }
                        about_link {
                          slug
                          url
                        }
                        about_link_text {
                          text
                        }
                        about_subheading_1 {
                          text
                        }
                        about_subheading_2 {
                          text
                        }
                        about_subheading_3 {
                          text
                        }
                        about_text_1 {
                          text
                        }
                        about_text_2 {
                          text
                        }
                        about_text_3 {
                          text
                        }
                        about_title {
                          text
                        }
                    }
                }
            }
        }
    }
`

const Home = ({ data }) => {
  if (!data) return null

  const document = data.allPrismicHome.edges[0].node.data

  const BackgroundImage = styled.div`
    background-image: url(${document.about_image.url});
    background-size: cover;
    background-position: center;
    min-height: 460px;
  `

  return (
    <div>
      <Layout>
            <Helmet>
                <title>{document.title.text}</title>
                <meta name="description" content={document.description.text} />
            </Helmet>
            <section id="index-hero">
            <div className="container">
                <h1 className="center-small text-blue">{document.hero_title.text}</h1>
                <p className="font-large font-center margin-none center-small">{document.hero_text.text}</p>
                <img src={document.hero_image.url} alt={document.hero_image.alt} />
            </div>
            </section>
            <section className="featured-columns">
                <div className="container">
                    <div className="three-columns">
                        <div>
                            <img src={document.cta1_image.url} alt={document.cta1_image.alt} />
                            <p className="font-large font-heavy margin-none mt-12">{document.cta1_header.text}</p>
                            <p>{document.cta1_text.text}</p>
                            <a href={document.cta1_link.url} className="link">{document.cta1_link_text.text}</a>
                        </div>
                        <div>
                            <img src={document.cta2_image.url} alt={document.cta2_image.alt} />
                            <p className="font-large font-heavy margin-none mt-12">{document.cta2_header.text}</p>
                            <p>{document.cta2_text.text}</p>
                            <a href={document.cta2_link.url} className="link">{document.cta2_link_text.text}</a>
                        </div><div>
                            <img src={document.cta3_image.url} alt={document.cta3_image.alt} />
                            <p className="font-large font-heavy margin-none mt-12">{document.cta3_header.text}</p>
                            <p>{document.cta3_text.text}</p>
                            <a href={document.cta3_link.url} className="link">{document.cta3_link_text.text}</a>
                        </div>
                    </div>
                </div>
            </section>
            
            <section id="index-about">
            <div className="container">
                <div className="two-columns">
                <div>
                  <h2 className="font-center">{document.about_title.text}</h2>
                  <p className="font-large font-heavy margin-none">{document.about_subheading_1.text}</p>
                  <p>{document.about_text_1.text}</p>
                  <p className="font-large font-heavy margin-none mt-24">{document.about_subheading_2.text}</p>
                  <p>{document.about_text_2.text}</p>
                  <p className="font-large font-heavy margin-none mt-24">{document.about_subheading_3.text}</p>
                  <p className="mb-24">{document.about_text_3.text}</p>
                  <a href={document.about_link.url} className="link">{document.about_link_text.text}</a>
                </div>
                <BackgroundImage></BackgroundImage>
                </div>
            </div>
            </section>
        </Layout>
    </div>
  )
}

export default Home